<template>
  <header>
    <template v-if="isMobile">
      <v-app-bar color="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-img
          max-width="32px"
          :src="require('@/assets/logo/chia-logo-shopping-cart-circle-32.png')"
          @click="toHome"
        />
        <v-toolbar-title class="ml-2" @click="toHome"
          >Chia 企芽</v-toolbar-title
        >
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav>
          <v-list-item-group active-class="primary--text text--accent-4">
            <template v-for="nav of navs">
              <v-list-item :key="nav.title" :to="nav.to" v-if="nav.to">
                <v-list-item-title>
                  {{ nav.title }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="nav.title"
                :href="nav.href"
                target="blank"
                v-else
              >
                <v-list-item-title>
                  {{ nav.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>
    <nav id="headerNav" v-else>
      <ul class="headerNavUl">
        <li class="headerNavLi" v-for="nav of navs" :key="nav.title">
          <router-link class="appAb" :to="nav.to" v-if="nav.to">{{
            nav.title
          }}</router-link>
          <a class="appAb color" :href="nav.href" target="_blank" v-else>{{
            nav.title
          }}</a>
        </li>
        <!--<li class="headerNavLi">消費者操作流程</li>-->
        <!--<li class="headerNavLi">線上說明會</li>-->
      </ul>
    </nav>
  </header>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      drawer: false,
      navs: [
        {
          title: "Chia 企芽",
          to: { name: "Home" }
        },
        {
          title: "申請開店",
          href: "https://apply.chia-market.com/"
        },
        {
          title: "功能服務",
          to: { name: "service" }
        },
        {
          title: "開店講座",
          to: { name: "lecture" }
        },
        {
          title: "Blog",
          to: { name: "blog" }
        },
        {
          title: "推薦文章",
          to: { name: "article" }
        },
        {
          title: "方案價格",
          to: { name: "Price" }
        }
      ]
    };
  },
  methods: {
    toHome() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
