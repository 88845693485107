import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import LoadingOverlay from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("loading-overlay", LoadingOverlay);

import PhoneNumberInput from "@/components/PhoneNumberInput";
Vue.component("phone-number-input", PhoneNumberInput);

import currencyFilter from "./filters/currency.js";
Vue.filter("currency", currencyFilter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
