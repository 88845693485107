<template>
  <div id="main">
    <!-- 區塊1-作圖
    <section id="cooperation">
      <h1>合作提案</h1>
    </section>
    -->

    <section id="cooperationForm">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="姓名"
          required
        ></v-text-field>

        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          label="電話"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="電子信箱"
          required
        ></v-text-field>

        <v-select
          v-model="select"
          :items="items"
          :rules="[v => !!v || '提案必填']"
          label="提案類別"
          required
        ></v-select>

        <v-textarea
          v-model="textarea"
          :rules="textareaRules"
          autocomplete="textarea"
          label="提案內容"
          required
        ></v-textarea>

        <v-btn id="cooperationFormBtn"
          :disabled="!valid"
          color="black"
          class="mr-4"
          outlined
          plain
          @click="validate"
          >
          確認送出
        </v-btn>
      </v-form>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || '姓名必填',
        v => (v && v.length <= 10) || '姓名超過字數，請重新輸入',
      ],
      phone: '',
      phoneRules: [
        v => !!v || '電話必填',
        v => (v && v.length <= 10) || '電話超過字數，請重新輸入',
      ],
      email: '',
      emailRules: [
        v => !!v || '電子郵件必填',
        v => /.+@.+\..+/.test(v) || '電子郵件為無效，請重新輸入',
      ],
      select: null,
      items: [
        '提案01',
        '提案02',
        '提案03',
        '其他提案',
      ],
      textarea: '',
      textareaRules: [
        v => !!v || '提案內容必填',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
    },
  }
</script>

<style lang="scss">
#main {//background-color: aquamarine;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002C74;
  margin: 50px 0 250px 0;//footer-bottom
}

#cooperation {min-height:700px; background-color: rgb(224, 197, 140);
  width: 60%;//width: 850px;
  margin: 0 auto;
  text-align: center;
}
#cooperationForm {
  width: 60%;//width: 850px;
  margin: 20px auto;
  text-align: center;
}

#cooperationFormBtn {
  margin-top: 20px;
  border: 1px solid rgb(75, 74, 74)!important;
}

</style>