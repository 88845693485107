<template>
  <div id="main">
    <!-- 區塊1-作圖
    <section id="service01">
      <h1 class="serviceTitle01">Chiau提供多元全面的方案一手帶領您邁向新的先機</h1>
    </section>
    -->
    <section id="service02">
      <h1 class="serviceTitle01">特殊功能</h1>
      <div :class="{ 'd-flex': !isMobile }">
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">收集數據</h3>
          <p class="serviceText">
            藉由客戶的紀錄參數，瞭解用戶的購物習慣，精準描繪個人的消費行為
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">數據分析</h3>
          <p class="serviceText">
            依劇各項分析工具的統計，深入瞭解成效，有效優化再行銷的轉換率
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">推播行銷</h3>
          <p class="serviceText">
            主動與顧客傳遞訊息，讓客群一次可見，不但增加粉絲，消息也不漏接
          </p>
        </div>
      </div>

      <div :class="{ 'd-flex': !isMobile }">
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">訂單、退貨單</h3>
          <p class="serviceText">
            提供訂貨單、退貨單等等的系統，協助您許多接單管理的程序
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">金流、物流</h3>
          <p class="serviceText">
            可選擇不同的付款方式以及配送機制，讓消費者擁有更多元的選擇
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">商品與庫存</h3>
          <p class="serviceText">
            具有完善的上下架功能與編輯商品的資訊，另外也有庫存串接的服務
          </p>
        </div>
      </div>

      <div :class="{ 'd-flex': !isMobile }">
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">顧客留言</h3>
          <p class="serviceText">
            透過對話框服務，自動即時回覆訊息，達到輕鬆與顧客互動，減輕人力壓力
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">問題回饋</h3>
          <p class="serviceText">
            如有任何問題，另有提供諮詢的服務，由專人替你解答任何的疑惑
          </p>
        </div>
        <div class="serviceBox">
          <!--<div class="serviceImg"></div>-->
          <h3 class="serviceWord">優惠折扣、簽到集點</h3>
          <p class="serviceText">
            擴充其他促銷活動的功能，提供給客戶專屬優惠，建立品牌的良好形象
          </p>
        </div>
      </div>
    </section>

    <!-- <h1 class="serviceTitle02">功能比較</h1>
    <section id="serviceTable01">
      <v-simple-table id="serviceTable">
        <template v-slot:default>
          <thead>
            <tr>
              <th id="serviceTableTh"></th>
              <th id="serviceTableTh">chia企芽</th>
              <th id="serviceTableTh">其他商城</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in desserts01"
              :key="item.name"
            >
              <td>{{ item.方案 }}</td>
              <td>{{ item.企芽 }}</td>
              <td>{{ item.其他商城 }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>

    <section class="serviceTable02">
      <v-simple-table id="serviceTable">
        <template v-slot:default>
          <thead>
            <tr>
              <th id="serviceTableTh"></th>
              <th id="serviceTableTh">chia企芽</th>
              <th id="serviceTableTh">其他機器人</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in desserts02"
              :key="item.name"
            >
              <td>{{ item.方案 }}</td>
              <td>{{ item.企芽 }}</td>
              <td>{{ item.其他機器人 }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Service",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      // desserts01: [
      //   {
      //     方案: '回覆訊息',
      //     企芽: '自動回覆',
      //     其他商城: '客服回覆',
      //   },
      //   {
      //     方案: '快速接收資訊',
      //     企芽: '有提供',
      //     其他商城: '需搭配App',
      //   },
      //   {
      //     方案: '訂單成立方式',
      //     企芽: '自動成立訂單',
      //     其他商城: 'App需轉回網站下單',
      //   },
      //   {
      //     方案: '與顧客互動',
      //     企芽: '主動雙向互動',
      //     其他商城: '被動單向互動',
      //   },
      //   {
      //     方案: '裝機器人功能',
      //     企芽: '有提供',
      //     其他商城: '需先架設網站再安裝',
      //   },
      //   {
      //     方案: '人力與時間',
      //     企芽: '大量減少',
      //     其他商城: '耗費較多',
      //   },
      // ],
      // desserts02: [
      //   {
      //     方案: '購物車',
      //     企芽: '有提供',
      //     其他機器人: '無提供',
      //   },
      //   {
      //     方案: '訂單成立方式',
      //     企芽: '自動成立訂單',
      //     其他機器人: '人工回覆確認',
      //   },
      //   {
      //     方案: '推播消息',
      //     企芽: '可一次群發',
      //     其他機器人: '一對一發送',
      //   },
      //   {
      //     方案: '收集用戶資料',
      //     企芽: '有提供',
      //     其他機器人: '無提供',
      //   },
      //   {
      //     方案: '資料分析工具',
      //     企芽: '提供不同功能',
      //     其他機器人: '自行分析瞭解',
      //   },
      //   {
      //     方案: '多樣活動',
      //     企芽: '多種模組可選',
      //     其他機器人: '只有簡單的促銷方式',
      //   },
      // ],
    };
  }
};
</script>

<style lang="scss">
#service01 {
  min-height: 700px;
  background-color: rgb(224, 197, 140);
  width: 60%; //width: 850px;
  margin: 0 auto;
  text-align: center;
}
#service02 {
  //border: 1px solid #000;
  width: 60%; //width: 850px;
  margin: 20px auto;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.serviceWrapper {
  display: flex;
}
.serviceBox {
  //min-height: 300px;
  flex: 1;
  background-color: rgb(221, 221, 221);
  margin: 0 5px 10px 5px;
  border-radius: 5px;
}
.serviceImg {
  background-color: rgb(224, 197, 140);
  min-height: 125px;
  width: 85%;
  margin: 20px auto;
}
#serviceTable01 {
  //border: 1px solid #000;
  width: 60%; //width: 850px;
  margin: 0 auto 20px auto;
}
.serviceTable02 {
  //border: 1px solid #000;
  width: 60%; //width: 850px;
  margin: 0 auto;
}

.serviceTitle01 {
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}
.serviceTitle02 {
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.serviceWord {
  //background-color: rgb(224, 197, 140);
  color: #002c74;
  font-size: 18px;
  margin-bottom: 10px;
}
.serviceText {
  //background-color: rgb(140, 197, 224);
  color: #002c74;
  font-size: 16px;
  padding: 0 45px;
  line-height: 2;
}
#serviceTableTh {
  color: #002c74;
  font-size: 16px;
}
#serviceTable {
  //background-color: rgb(224, 197, 140);
  color: #002c74;
}
</style>
