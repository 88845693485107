<template>
  <div id="main">
    <v-container class="text-center">
      <v-row justify="center">
        <v-col :cols="isMobile ? 12 : 8">
          <h1 class="articleTitle my-5">推薦文章</h1>
          <v-card>
            <div class="d-flex mobile-column">
              <img
                class="img-fluid"
                src="../assets/article/article01.jpg"
                alt="telegram chia"
              />
              <div class="text-center pt-3 flex-grow-1">
                <h3 class="articleWord">
                  空降排行榜冠軍，吸走大票Line用戶！<br />堪稱「完美通訊軟體」<br />Telegram殺手級優勢一次公開
                </h3>
                <p class="articleText">類別：文章</p>
                <p class="articleText">時間：2020/02/27</p>
                <p class="my-7">
                  <v-btn
                    outlined
                    href="https://www.storm.mg/lifestyle/2326634?page=1"
                    target="_blank"
                  >
                    瞭解更多
                  </v-btn>
                </p>
              </div>
            </div>
          </v-card>

          <v-divider class="my-5"></v-divider>

          <v-card>
            <div class="d-flex mobile-column">
              <img src="../assets/article/article03.jpg" alt="telegram chia" />
              <div class="text-center pt-3 flex-grow-1">
                <h3 class="articleWord">
                  為什麼慈濟要棄守LINE官方帳號<br />投向Telegram懷抱
                </h3>
                <p class="articleText">類別：影片</p>
                <p class="articleText">時間：2020/01/20</p>
                <p class="my-7">
                  <v-btn
                    outlined
                    href="https://www.youtube.com/watch?v=KsK3zqRFfrY"
                    target="_blank"
                    >瞭解更多</v-btn
                  >
                </p>
              </div>
            </div>
          </v-card>

          <v-divider class="my-5"></v-divider>

          <v-card>
            <div class="d-flex mobile-column">
              <img src="../assets/article/article02.jpg" alt="telegram chia" />
              <div class="text-center pt-3 flex-grow-1">
                <h3 class="articleWord">
                  5個關於Line@不可思議的事實<br />看完後我驚呆了
                </h3>
                <p class="articleText">類別：文章</p>
                <p class="articleText">時間：2016/10/22</p>
                <p class="my-7">
                  <v-btn
                    outlined
                    href="https://blog.user.today/5-experiences-about-line-at/"
                    target="_blank"
                  >
                    瞭解更多
                  </v-btn>
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Article",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  }
};
</script>

<style lang="scss">
.articleTitle {
  color: #000;
  font-size: 20px;
}
.articleWord {
  color: #002c74;
  font-size: 18px;
  margin: 15px 0;
}
.articleText {
  color: #002c74;
}

.mobile-column {
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
</style>
