import { call } from ".";

/**
 * get invitation code
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} user_id, invitation_code_id and invitation_code
 */
const getInvitationCode = function(idToken, userID) {
  return call("getInvitationCode", idToken, {
    user_id: userID
  });
};

/**
 * is invitation code exist
 * @param {string} idToken
 * @param {string} invitationCode
 * @returns {Promise<Object>} invitation_code_id and is_exist
 */
const isInvitationCodeExist = function(idToken, invitationCode) {
  return call("isInvitationCodeExist", idToken, {
    invitation_code_id: invitationCode
  });
};

export { getInvitationCode, isInvitationCodeExist };
