<template>
  <v-footer class="flex-column pa-0">
    <nav id="footerNav">
      <ul class="footerNavUl">
        <h1 class="appFooterTitle">產品功能</h1>
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'service' }"
            >功能服務</router-link
          >
        </li>
        <!--<li class="footerNavLi"><router-link class="appAs" :to="{name: 'price'}">方案價格</router-link></li>
          <li class="footerNavLi">推廣工具</li>
          <li class="footerNavLi">結帳體驗</li>
          <li class="footerNavLi">部落格功能</li>
          <li class="footerNavLi">客戶關係管理</li>
          <li class="footerNavLi">消費者操作流程</li>-->
      </ul>
      <ul class="footerNavUl">
        <h1 class="appFooterTitle">擴充模組</h1>
        <!--<li class="footerNavLi">銷售管道</li>
          <li class="footerNavLi">行銷推廣</li>
          <li class="footerNavLi">社交媒體</li>
          <li class="footerNavLi">客戶關係</li>
          <li class="footerNavLi">數據分析</li>-->
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'install' }"
            >Telegram安裝</router-link
          >
        </li>
      </ul>
      <ul class="footerNavUl">
        <h1 class="appFooterTitle">更多資源</h1>
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'lecture' }"
            >開店講座</router-link
          >
        </li>
        <!--<li class="footerNavLi">線上說明會</li>-->
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'blog' }">blog</router-link>
        </li>
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'article' }"
            >推薦文章</router-link
          >
        </li>
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'cooperation' }"
            >合作提案</router-link
          >
        </li>
        <!--<li class="footerNavLi">公益團體</li>-->
      </ul>
      <ul class="footerNavUl">
        <h1 class="appFooterTitle">關於我們</h1>
        <li class="footerNavLi">
          <router-link class="appAs" :to="{ name: 'Home' }"
            >chia 企芽</router-link
          >
        </li>
        <li class="footerNavLi">
          <a
            href="https://apply.chia-market.com/"
            target="_blank"
            class="appAs font-weight-bold color"
            >申請開店</a
          >
        </li>
      </ul>
    </nav>

    <div id="footerDetail">
      <div class="footerDetailBox">
        <p class="appFooterText">
          Copyright &copy; {{ new Date().getFullYear() }} chia版權所有
        </p>
      </div>
      <div class="footerDetailBox">
        <ul class="footerSecUl">
          <li class="footerSecLi">
            <router-link class="appAs" :to="{ name: 'privacyPolicy' }"
              >隱私條款</router-link
            >
          </li>
          <li class="footerSecLi">
            <router-link class="appAs" :to="{ name: 'termsService' }"
              >服務條款</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="service_title" v-if="$route.name === 'StoreOnePageWeb'">
      {{ $t("__service_by_chia_title") }}
      <router-link :to="{ name: 'Home' }" style="color: white">{{
        $t("__chia_e-platform")
      }}</router-link>
    </div>
  </v-footer>
</template>
<script>
export default {
  name: "Footer"
};
</script>
