<template>
  <div id="main">
    <!-- 區塊1-作圖
    <section id="blog01">
      <h1 class="blogTitle01">瞭解更多關於Chia的訊息，歡迎至下方找到相關回覆</h1>
    </section>
    -->
    <v-container fluid class="text-center">
      <v-row justify="center">
        <v-col :cols="isMobile ? 12 : 8">
          <h1 class="blogTitle02">部落格分享</h1>
          <div class="mt-5">
            <div class="blogImg">
              <v-img src="../assets/blog/blog01.jpg" />
            </div>
            <div class="my-5">
              <h3 class="blogWord">部落客使用心得</h3>
              <p class="blogText">
                已經有業主開始嘗試
                chia企芽機器人「KittenBotTW品牌專櫃」，幫助您在銷售上能夠更省力、更省心。您想知道它有多便捷嗎？趕快來使用！
              </p>
            </div>
          </div>
          <v-divider></v-divider>

          <h1 class="blogTitle02 my-5">問答專區</h1>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">關於chia企芽的簡介、服務說明</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                chia企芽於2021年成立，我們的銷售機器人致力於全方位功能，訴求快速、簡單、好操作，提供更優質的購物體驗。我們整併虛擬電商與他家機器人的優勢，讓商家一次享有兩種服務，另外還擴充許多模組，協助您輕鬆營運。
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">如何開店?有什麼方案或限制?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                chia企芽提供各種方案可供商家選擇，商家可以在我們的後台設定相關功能，幫助商家建置資料。不同的方案搭配不同的使用規範，詳細介紹可敬請參閱<router-link
                  id="blogBtn"
                  :to="{ name: 'Price' }"
                  >方案價格</router-link
                >，此處亦有說明各個方案的適合商家，可供商家參考。
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">chia企芽機器人的與其他家的不同之處?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                chia企芽的銷售機器人結合電商與機器人的服務，使商家一次付費即可包含兩種功能，另有擴充多樣化的活動模組，以及透過用戶參數，加上後台的數據分析，讓商家能更加全面地清楚知道營運狀況，達到更完善的優化。
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">提供哪些金流、物流服務?如何操作?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                chia企芽在金流與物流方面，提供多元的選項。商家可以在後台的設定介面中，依照自己的需求，自行選擇想要的服務項目，甚至包含列印標籤與備料單等等的功能，協助商家在寄送商品時更加便捷。
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">有新手的教學手冊嗎?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                當商家開始使用chia企芽的銷售機器人時，可以在我們的後台找到使用操作教學的選項，內容有chia企芽提供詳盡的教學手冊，不論您是初期創業的新手商家，還是第一次使用我們後台的商家，均可幫助您使用上能夠更加順利。
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="blogWord">想與chia企芽聯繫有哪些管道?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="blogPanelsText">
                商家在經營時如有遇到任何的問題，chia企芽的後台有問題會饋的功能，由專人替您解答。若商家尚未使用chia企芽的銷售機器人，我們則歡迎您報名我們的<router-link
                  id="blogBtn"
                  :to="{ name: 'lecture' }"
                  >開店講座</router-link
                >，蒞臨於現場作諮詢的服務，同時還可獲得更多的相關資訊。
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Blog",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  }
};
</script>

<style lang="scss">
.blogTitle01 {
  color: #000;
  font-size: 20px;
}
.blogTitle02 {
  color: #000;
  font-size: 20px;
  border-left: 2px solid #474747;
  border-right: 2px solid #474747;
  display: inline-block;
  padding: 0 10px;
}
.blogWord {
  color: #002c74;
  font-size: 18px;
}
.blogText {
  color: #002c74;
  font-size: 16px;
  padding-top: 10px;
}
#blogBtn {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.blogPanelsText {
  color: #002c74;
  font-size: 16px;
  text-align: left;
}
</style>
