<template>
  <div id="main">
    <v-container>
      <v-row justify="center">
        <v-col :cols="isMobile ? 12 : 8">
          <h1 class="termsServiceTitle">使用服務條款</h1>
          <h3 class="termsServiceWord mb-2 pl-3">歡迎使用 Chia !!</h3>
          <p class="pl-4">
            感謝您使用台灣機器人電商股份有限公司
            (地址為台灣台北市中正區師大路170-3號，以下簡稱「台灣機器人電商」)
            所架設的 Chia
            平台（https://chia.com）所提供之服務（以下簡稱「本服務」），讓用戶可在特定通訊軟體上裝置聊天機器人
            (以下簡稱「ChatBot」)以和受眾進行互動。
          </p>
          <v-divider></v-divider>

          <h3 class="termsServiceWord mb-2 mt-4 pl-3">概要</h3>
          <ol start="0" class="term-list list-style-none pl-0">
            <h3 class="termsServiceWord03"><li>0.定義</li></h3>
            <p class="pl-4 mt-2">本條款使用下列各項用語：</p>
            <li>
              0-1
              「內容」係指文字、語音、音樂、圖片、影片、軟體、程式、代碼及其他資訊等內容。
            </li>
            <li>
              0-2 「本內容」係指可透過本服務連結的內容。
            </li>
            <li>
              0-3 「投稿內容」係指用戶投稿、傳送、上傳至本服務的內容。
            </li>
            <li>
              0-4 「代幣」係指可兌換本服務中有償提供的服務或內容的電子虛擬貨幣。
            </li>
            <li>
              0-5
              「個別使用條款」係指與本服務有關而有別於本條款，以「條款」、「準則」、「政策」等名稱由本公司所分發或公布的書面內容。
            </li>
            <br />
            <h3 class="termsServiceWord03">
              <li>
                1.同意使用，請確實閱讀以下使用條款(以下簡稱「使用條款」)
              </li>
            </h3>
            <li>
              1-1
              理解並同意，一旦用戶勾選「確認」或類似按鍵以存取本服務，或以其他方式存取或使用本服務，即表示已閱讀、了解並完全同意本服務條款之所列內容。若不同意本條款內容，應立即停止本服務之使用。
            </li>
            <li>
              1-2
              若用戶為未滿二十歲之未成年人，除應符合上述規定外，須經過親權人等法定代理人同意後，方能使用本服務。當開始使用或繼續使用本服務時，即視為您的法定代理人已閱讀、瞭解並同意接受本使用條款的所有約定及其後修改或變更。
            </li>
            <li>
              1-3
              本服務一經用戶實際使用，將視為用戶已有效且不可撤銷地同意本條款。
            </li>
            <li>
              1-4
              chia保留隨時修改本服務或使用條款之權利，修改後的內容將公告於本網站相關網頁上即時生效，不另行通知。誠摯建議您定期確認服務條款是否有修改。若用戶在
              Chia
              修改服務條款後仍繼續使用本服務，即代表已接受此修改條款之規範。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>2.使用本服務</li></h3>
            <li>
              2-1 本服務包含提供一個購物機器人平台服務，以為買家 (「買家」)
              與賣家 (「賣家」) (統稱「您」、「用戶」或「買賣雙方」)
              間的商品交易提供場所及機會。實際的銷售合約存在於買家及賣家之間，chia不是該合約或買家與賣家其他合約之間的主體，且chia對這些合約均不承擔義務。買賣雙方將承擔有關其間銷售合約、商品刊登、購物擔保及類似事項之全部責任。chia不涉入用戶間之交易，並不確保用戶會確實完成交易。
            </li>
            <li>
              2-2 進行 Chia
              平台註冊程序時，可能會透過您於第三方之帳號、密碼登入，以節省您註冊所需填寫資料的時間，當您以第三方之帳號、密碼登入時，系統會自動將您於第三方註冊之資訊提供予您確認，用戶於確認後即同意以該等資料向
              Chia 平台進行註冊程序。
            </li>
            <li>
              2-3
              用戶有義務妥善保管帳號與密碼，並為此組帳號與密碼登入系統後所進行之一切活動負責。為維護用戶自身權益，請妥善保存並維持密碼及帳號的機密安全，並在知悉非授權使用時立即通知
              Chia。
            </li>
            <li>
              2-4
              「當輸入的帳號及密碼與登錄資料一致時，均視為用戶合法使用，即使係遭盜用、不當使用或其他
              Chia 平台無法辨識是否為本人親自使用之情況，Chia
              對此所致之損害概不負責。
            </li>
            <li>
              2-5 於本服務註冊的用戶，得隨時刪除帳號終止本服務。
            </li>
            <li>
              2-6
              自帳號刪除時起，用戶於本服務的所有使用權不論任何理由均為消滅。即便是用戶不慎刪除帳號，原有的帳號亦無法回復，敬請留意。
            </li>
            <li>
              2-7
              本服務的帳號專屬於用戶個人。用戶於本服務的所有使用權皆不得轉讓、出借予第三人或使第三人繼承。
            </li>
            <li>
              2-8
              本公司認為用戶違反本條款或有違反之虞時，得在不事先通知用戶的情形下對該帳號進行停權或刪除。
            </li>
            <li>
              2-9
              chia保留隨時因應法規要求或符合誠信原則而變更、修改、暫停或中止本服務一部或全部的權利。chia可能會以試用版發行某些服務或其功能，這些服務或功能可能無法正確運作或如同最終發行版本般運作，亦可對某些功能設定限制，或限制您存取部分或全部或服務之權限。
            </li>
            <li>
              2-10
              本服務條款中的任何約定不得解讀為您與chia之間的合夥、合資關係或主要代理人關係，亦未授權您得代表chia產生任何費用或責任。
            </li>
            <li>
              2-11
              chia與消費者得以電子文件為表示方法，依本服務條款交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與實體書面文件相同。但依法令或行政機關之公告排除適用者，不在此限。本服務條款內容所指書面方式，除另有特別約定外，均包含實體書面及電子郵件。
            </li>
            <li>
              2-12
              如使用者在本平台發生任何退貨退款以外之消費爭議，得透過客服信箱或客服電話向chia要求協助處理，並依chia要求在指定期限內提供訂單、物流運送、商品寄送或買賣雙方聊天紀錄等相應支持文件，chia將盡速完成調查後，依其符合誠信原則決定採取停權或其他協助使用者解決糾紛的必要措施。
            </li>
            <li>
              2-13 chia保留在本協議未明文授予的所有權利。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>3.公司行號使用本服務</li></h3>
            <li>
              3-1
              如果用戶是代表某公司使用本服務，即表示該公司接受本條款。該公司必須就任何因使用本服務或違反本條款而引起或相關的請求、訴訟或法律行動
              (包括承擔所有因請求、損失、損害、訴訟、判決、訴訟費用和律師費用而產生的任何責任或費用)，向
              Chia
              及其關係企業、主管人員、代理人和員工做出補償，並使其不受損害。
            </li>
            <br />
            <h3 class="termsServiceWord03">
              <li>4.遵守法令與相關政策義務</li>
            </h3>
            <li>
              4-1 用戶同意使用本服務時遵循所有適用法規，並同意對所有提交至 Chia
              平台之內容或通訊內容負責，包括文字與圖像內容(總稱為「提交內容」)。
            </li>
            <li>
              4-2 用戶同意不會上傳、分享或以其他方式散布含有以下性質之提交內容:
            </li>
            <ol>
              <li class="item">
                非法、威脅、辱罵、騷擾、誹謗、欺騙、詐欺，侵犯他人隱私或冒充他人；
              </li>
              <li class="item">
                以明確或圖形方式描述或記錄性行為（包括但不限於以暴力或威脅性的性語言），或以任何方式違反任何法令；
              </li>
              <li class="item">
                基於宗教、性別、性取向、種族、民族、年齡或障礙而迫害、騷擾、貶低或恐嚇任何他人或團體；
              </li>
              <li class="item">
                侵犯任何專利、商標、營業機密、著作權或任何他人權利；
              </li>
              <li class="item">廣告郵件、垃圾郵件或連鎖信件；</li>
              <li class="item">
                包含設計或預定用來破壞、損壞或限制任何軟硬體或電信設備的功能，或者包含損壞或獲得未經授權訪問任何第三方數據或其他資訊的軟體病毒或任何其他電腦程式碼、文件或程式；
              </li>
              <li class="item">
                包含可用於確定本網站架構的措施，或可用於對網站進行反組譯、反彙編或反向工程的措施。
              </li>
            </ol>
            <li>
              4-3
              chia不會為用戶提交內容背書，也不會事前審查或監控提交內容，但仍保有移除任何認定可能違反本服務條款或有害、令人反感或不準確的內容之權利。chia不會對任何移除該等內容所造成的失敗或遲延負責，且會主動提交或配合相關單位處理。
            </li>
            <li>
              4-4
              用戶應遵守並同意使用本服務所產生之「ChatBot」裝置之管道（包括但不限於
              Facebook Messenger、LINE、Wechat、WhatsAlip 及
              Telegram）所約定之相關政策。
            </li>
            <li>
              4-5
              違反此政策可能導致一系列處分動作，包括但不限於下列任何或全部項目：
            </li>
            <ul class="list">
              <li class="item">刪除刊登商品</li>
              <li class="item">限制帳戶權限</li>
              <li class="item">中止帳戶及隨後終止</li>
              <li class="item">刑事訴訟</li>
              <li class="item">
                民事求償，包括但不限於請求損害賠償及/或聲請保全處分
              </li>
            </ul>
            <li>
              4-6 如果您發現本網站的任何使用者違反本服務條款，請絡
              service@chia-market.com。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>5.提交內容之授權</li></h3>
            <li>
              5-1
              當用戶透過本服務將提交內容上傳、提交、儲存、傳送或以任何方式提供予
              Chia 平台時，即表示同意授予 Chia
              及其合作夥伴全球性的授權，可使用、代管、儲存、重製、修改、衍生著作、公開播送、公開傳輸、公開演出、改作、散布、出版、公開發表提交內容。
            </li>
            <li>
              5-2
              本公司就所提供的本內容授予用戶使用權，此使用權並不得轉讓及再授權，且具有非獨占性，並以使用本服務為唯一目的。
            </li>
            <li>
              5-3
              如用戶使用的本內容另有規定使用費、使用期間等使用條件時，即應遵照該等使用條件。即使本服務介面上有顯示「購買」、「販賣」等文字，本公司對用戶所提供的本內容相關智慧財產權及其他權利亦不因此移轉給用戶，對用戶僅有授予上述使用權。
            </li>
            <li>
              5-4
              若用戶無合法權利得授權他人前項權利內容，請勿擅自將該提交內容上傳、提交、儲存、傳送或以任何方式提供予
              Chia 平台。
            </li>
            <li>
              5-5
              用戶於本項授權授予的權利僅限用於營運、宣傳與改善本服務，以及開發新的服務。即使用戶停止使用本服務，本項授權仍持續具有效力。
            </li>
            <li>
              5-6
              請用戶自行儲存投稿內容的備份。本公司並無義務儲存投稿內容的備份。
            </li>
            <li>
              5-7
              本公司認為用戶投稿內容違反相關法令或本條款規定或有違反之虞時，或有其他業務上需求時，得在不事先通知用戶的情形下，以刪除投稿內容等方式限制本服務中投稿內容的使用。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>6.智慧財產權與本服務</li></h3>
            <li>
              6-1
              本服務之免月費版本會有部分功能限制及「ChatBot」的浮水印廣告或其它型式廣告，包含但不限於歡迎畫面、功能表及對話內容。
            </li>
            <li>
              6-2 Chia 平台及本服務上所有內容，包括但不限於
              LOGO、商標、著作、圖片、檔案、文件、資訊、資料、網站架構、網站畫面的安排、網頁設計、軟體、影音、會員資料等等，均由
              Chia 或其相關權利人依法擁有智慧財產權。
            </li>
            <li>
              6-3 Chia
              於茲授予您全球性、免權利金、不得轉讓、非專屬的個人使用本服務之授權。本授權僅供您以使用條款允許之方式，使用
              Chia 提供之本服務並享用其利益。
            </li>
            <li>
              6-4 未經 Chia 事前書面同意，您不得將 Chia
              平台及本服務上所有內容，為本服務提供目的以外使用、修改、衍生著作、重製、公開播送、公開傳輸、公開演出、改作、散布、出版、公開發表、進行還原工程、破解或反向組譯。
            </li>
            <li>
              6-5 若您欲引用或轉載前述內容，必須依法取得 Chia
              或其他權利人的事前書面同意。如有違反，您應對 Chia
              或其相關權利人負損害賠償責任。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>7.免責聲明</li></h3>
            <li>
              7-1
              不論明示或默示，本公司均未保證本服務（包括本內容）不具有事實瑕疵或法律瑕疵（包括安全性、可靠性、正確性、完整性、有效性、特定目的的適用性、安全等相關缺陷、錯誤或程式錯誤、權利侵害等）。本公司對用戶並無清除該等瑕疵後再提供本服務的義務。
            </li>
            <li>
              7-2
              因本服務而對用戶所造成的所有損害，本公司將不負任何責任。但本公司與用戶間的本服務相關契約（包括本條款）為消費者契約法規定的消費者契約時，則不適用此免責規定。
            </li>
            <li>
              7-3
              即使在前述第7-2項但書所規定情形下，在因本公司過失（重大過失除外）所發生的債務不履行或侵權行為對用戶所造成的損害中，本公司對於特殊事由所造成的損害（包括本公司或用戶就損害發生已預見或有可能預見者），亦概不負其責。此外，因本公司過失（重大過失除外）所發生債務不履行或侵權行為對用戶所造成的損害，其賠償以發生該等損害當月自用戶收取的使用金額為上限。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>8.用戶責任</li></h3>
            <li>
              8-1
              用戶應自行承擔責任使用本服務，對於在本服務所從事的所有行為及其結果應自行負擔一切責任。
            </li>
            <li>
              8-2
              本公司認為用戶在違反本條款情形下使用本服務時，將採取本公司判斷有必要且適當的措施。但此不代表本公司有義務防止或糾正該等違反情形。
            </li>
            <li>
              8-3
              起因於用戶使用本服務（包括本公司自第三人處收到原因為該等使用的申訴），致本公司直接或間接蒙受任何損害（包括律師費用的負擔）時，用戶應依照本公司要求立即給予補償。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>9.賣家責任</li></h3>
            <li>
              9-1
              賣家應妥善管理並確保其刊登之商品相關內容是最新資訊（例如商品名稱、價格、規格、型號及其他相關資訊等)，不應張貼不正確或誤導之內容。商品內容呈現之資訊，為成立的訂單內容之一部分。
            </li>
            <li>
              9-2
              銷售商品的價格由賣家自行決定。商品價格和運費應包含在向買家收取的總金額之內
              ，包含營業稅、增值稅、關稅等，賣家不應額外且另外向買家收取這類費用。
            </li>
            <li>
              9-3
              賣家同意chia得符合誠信原則決定透過降價、折扣、退費或其他方式來進行促銷活動，以促進買家與賣家之間的交易。買家實際支付的最終價格將會是適用這類調整之後的價格。
            </li>
            <li>
              9-4 為了促銷賣家所刊登之商品，chia可能會在第三方網站或服務
              (如入口網站和比價網站或其它購物服務) 及由chia經營的其他
              (國內或國外) 網站上 (以調整後的價格) 張貼這些商品。
            </li>
            <li>
              9-5 應買家要求，賣家必須提供收據、信用卡簽單或稅務發票給買家。
            </li>
            <li>
              9-6
              賣家知悉並同意所有與銷售商品相關的納稅責任應由賣家負擔，chia不提供相關法律或稅務上建議。且因稅務法規可能隨時更新，賣家如有任何疑問應即時尋求專業意見。
            </li>
            <li>
              9-7 賣家知悉並同意，若賣家違反任何chia政策，願接受條款第
              4-5條所述的一系列處分動作。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>10.費用</li></h3>
            <li>
              10-1 除非另有說明或約定，否則chia不會向用戶收取本服務的使用費。
            </li>
            <li>
              10-2
              如有應付費用則將包含服務稅/增值稅及其他適用稅項。除非雙方另有約定，賣家應承擔前述稅費。賣家知悉並同意，chia或其合作之金流服務商可從買家支付的購物金額中扣除其費用及任何適用稅項。如應賣家要求，chia應提供賣家所支付之費用和稅項的收據或稅務發票。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>11.付費廣告</li></h3>
            <li>
              11-1
              chia將陸續於本平台推出廣告推播及/或其他廣告服務（下稱「付費廣告」）歡迎賣家購買作為商品行銷策略的一部分。chia係依據本條規範及當時於本平台公布的廣告服務內容、購買流程和使用說明等（下稱「付費廣告規則」）提供付費廣告，您如申請購買付費廣告，即表示已充分詳閱、了解並同意所有付費廣告規則內容。您若不同意任何付費廣告規則的內容，請勿購買付費廣告。
            </li>
            <li>
              11-2
              您必須為符合付費廣告規則條件的賣家且為商品刊登者，才可購買付費廣告。以您購買且進行付費時為準，您的帳戶須非停權之狀態。
            </li>
            <li>
              11-3
              您購買付費廣告後，應依chia規定之方式給付廣告費用。除適用法令或付費廣告規則另有規定外，您一經申請購買付費廣告且完成繳款程序後，即不得取消訂單及/或請求返還款項。賣家同意chia按月結方式提供相應發票或電子發票，並保證提供正確有效的電子郵件信箱以即時接收之。
            </li>
            <li>
              11-4
              您得於開始刊登商品時，即同時選擇購買付費廣告，或於商品刊登期間內，選擇購買付費廣告。您購買付費廣告且完成繳款程序後，得依付費廣告規則為不同的商品設定不同的預算、關鍵字、行銷期間…等，該商品的付費廣告服務將依您的設定被啟用，直到期滿為止。您所設定的行銷期間內如該商品售出或下架，廣告費用將不予退還，您亦不得請求將剩餘的行銷期間轉讓予其他商品使用。
            </li>
            <li>
              11-5
              您刊登的商品應遵守相關法令、付費廣告規則、chia服務條款及禁止和限制商品政策，您瞭解並同意，如經發現或接獲檢舉您的商品有違反上述規定之虞，chia有權立即移除您違規的商品及廣告，對於您已繳交的廣告費用，恕不予退還；又，經主管機關或司法單位透過合法正式的程序要求chia下架的商品或廣告，將直接下架且不退還任何費用。基於本條事由所下架的商品或廣告，不負任何補償或賠償責任。
            </li>
            <li>
              11-6
              您瞭解並同意chia對買家經由付費廣告而瀏覽您商品的次數或您商品因此增加之售出機會，不提供任何保證。
            </li>
            <li>
              11-7
              建議您得斟酌當時預算和個別商品欲達到的行銷效果購買並運用付費廣告。除本服務條款或付費廣告規則另有規定外，chia不對廣告預期目標或效果負任何補償或賠償責任(包括但不限於實際支出費用和所失利益等)。
            </li>
            <li>
              11-8
              如依適用法令規定chia因您所購買的付費廣告應負任何損害賠償責任時，您瞭解並同意chia所負之責任總額應不逾您該次購買付費廣告所繳納之費用。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>12.第三方服務連結</li></h3>
            <li>
              12-1
              本服務上所提供之第三方連結將會帶您離開本服務。這些連結只是基於方便而提供，其連結之網站不受chia的控管，因此您應自行承擔存取這些網站的風險。因此，對於任何這類連結網站的內容或連結網站中所含之任何連結
              (包括這類網站之任何變動或更新)，chia概不負責。chia只是基於您使用方便而提供這些連結，提供任何連結並不默示或明示chia與任何連結網站及/或其任何內容有關聯、保證或贊助關係。
            </li>
            <br />
            <h3 class="termsServiceWord03">
              <li>13.第三方之內容與外部連結提交給本服務</li>
            </h3>
            <li>
              13-1
              提交資料、文字、影像、聲音、影片、軟體及其他內容給本服務的每位提供者，應對其提交內容之正確性、可靠性、性質、無權利負擔及法律和法定限制之合規性負完全責任。
            </li>
            <li>
              13-2
              此外，本服務可能包含第三方產品、網站、服務與方案之連結。這些第三方連結、產品、網站與服務並非由chia所有或控管，而是由第三方各自經營並為其所屬財產，且可能受到適用之著作權或其他智慧財產法律和條約之保護。這些第三方內容、功能、安全、服務、隱私權政策或其他規範均未經chia審核。我們建議您詳閱這些第三方在其網站或以其他方式提供的條款與其他政策。您亦知悉並同意，若本服務上的任何第三方連結或應用程式違反適用法規或本服務條款時，chia得移除之。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>14.責任限制</li></h3>
            <li>
              14-1 在法律准許的最大範圍內，Chia
              及其合作夥伴對用戶因使用或無法使用本服務所致之任何間接、連帶、特殊、衍生、附隨、懲罰性損害均不負責，包括但不限於所失利益，或資料或利潤之損失所生損害，且無論本公司是否已被告知此損害的可能性以及依據任何責任理論。
            </li>
            <li>
              14-2 在法律准許的最大範圍內，Chia
              及其合作夥伴對依本條款所遭提出任何請求之總責任
              (包括對任何默示擔保之責任)，均不超過用戶就使用本服務而向 Chia
              支付的金額 (或者 Chia 亦可選擇繼續使用本服務)。
            </li>
            <li>
              14-3 不論在任何情況下，Chia
              及其合作夥伴對無法合理預見之任何損失或損害均不負責。
            </li>
            <li>
              14-4
              本公司瞭解，在部分國家或地區中，用戶可能享有消費者應有之法定權利。如果係以個人目的使用本服務，則本條款中的任何規定均不應限制不得以契約方式拋棄之任何消費者法定權利。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>15.隱私權保護</li></h3>
            <li>
              15-1 Chia
              的《隱私權政策》會說明當使用本服務時，本公司會如何處理用戶個人資料並保護用戶隱私權。使用本服務時，即表示同意
              Chia 可依據本公司的隱私權政策的規定使用這類資料。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>16.終止</li></h3>
            <li>
              16-1
              chia得自行決定以任何理由終止或暫停用戶使用本服務，包括但不限於任何涉嫌詐欺、濫用或非法行為，且可能移交執法機構處理。
            </li>
            <li>
              16-2
              當本服務被終止或暫停，使用本服務的權利立即停止，且chia得立即停用或刪除用戶帳號以及所有相關資料。本公司不應因終止或暫停而對用戶或任何第三方負擔任何主張或損害賠償責任。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>17.通知</li></h3>
            <li>
              17-1
              當使用本服務，理解並同意本公司會向用戶發送服務公告、行政管理訊息和其他資訊，而用戶可取消接收其中某些通訊內容。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>18.準據法與管轄法院</li></h3>
            <li>
              18-1
              本服務條款之解釋與適用，以及與本服務條款有關的爭議，均以中華民國法律為準據法，並以台灣台北地方法院為第一審管轄法院。
            </li>
            <br />
            <h3 class="termsServiceWord03"><li>19.Chia的聯絡資訊</li></h3>
            <li>
              19-1 如果您有任何問題，歡迎來信至客服 service@chia-market.com
              與本公司聯繫。
            </li>
            <br />
          </ol>

          <v-divider></v-divider>

          <p class="time">更新日期2021年04月09日</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TermService",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  }
};
</script>

<style lang="scss">
.termsServiceTitle {
  text-align: center;
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}
.termsServiceWord {
  color: #000;
  font-size: 18px;
  border-left: 4px solid #474747;
}
.termsServiceWord03 {
  color: #000;
  font-size: 18px;
  padding-left: 15px;
}

.list {
  list-style-type: square;
}
.item {
  margin-left: 40px;
  margin-top: 5px;
}
.time {
  margin-top: 20px;
  text-align: right;
  padding-right: 10px;
}
.term-list > li {
  padding-left: 16px;
  line-height: 2;
}
</style>
