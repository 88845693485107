import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import service from "../views/service.vue";
import lecture from "../views/lecture.vue";
import operating from "../views/operating.vue";
import blog from "../views/blog.vue";
import article from "../views/article.vue";

import install from "../views/install.vue";
import cooperation from "../views/cooperation.vue";
import publicGroup from "../views/publicGroup.vue";

import privacyPolicy from "../views/privacyPolicy.vue";
import termsService from "../views/termsService.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/service",
    name: "service",
    component: service
  },
  {
    path: "/lecture",
    name: "lecture",
    component: lecture
  },
  {
    path: "/operating",
    name: "operating",
    component: operating
  },
  {
    path: "/price",
    name: "Price",
    component: () => import("@/views/Price/Price.vue")
  },
  {
    path: "/price/:id",
    name: "PriceIntro",
    component: () => import("@/views/Price/PriceIntro.vue")
  },
  {
    path: "/blog",
    name: "blog",
    component: blog
  },
  {
    path: "/article",
    name: "article",
    component: article
  },

  {
    path: "/install",
    name: "install",
    component: install
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: cooperation
  },
  {
    path: "/publicGroup",
    name: "publicGroup",
    component: publicGroup
  },

  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy
  },
  {
    path: "/termsService",
    name: "termsService",
    component: termsService
  },
  // one page web store
  {
    path: "/store/:id",
    name: "StoreOnePageWeb",
    component: () => import("@/views/StoreOnePageWeb.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
