<template>
  <div id="main">
    <v-container class="text-center">
      <h1 class="lectureTitle">講座內容</h1>
      <div class="d-flex">
        <div class="lectureBoxContext">
          <!--<div class="lecturePic" id="lecturePic01"></div>-->
          <h3 class="lectureWord02">操作簡單</h3>
        </div>
        <div class="lectureBoxContext">
          <!--<div class="lecturePic" id="lecturePic02"></div>-->
          <h3 class="lectureWord02">快速方便</h3>
        </div>
        <div class="lectureBoxContext">
          <!--<div class="lecturePic" id="lecturePic03"></div>-->
          <h3 class="lectureWord02">精準客戶</h3>
        </div>
        <div class="lectureBoxContext">
          <!--<div class="lecturePic" id="lecturePic04"></div>-->
          <h3 class="lectureWord02">簡省開支</h3>
        </div>
      </div>
      <v-row>
        <v-col :cols="isMobile ? 12 : 4">
          <v-card flat>
            <v-card-title class="justify-center font-weight-bold"
              >第一場次</v-card-title
            >
            <v-card-text>
              <p class="lectureText">時間：<br />時間暫未公布敬啟期待</p>
              <p class="lectureText">地點：<br />地點暫未決定敬啟期待</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="isMobile ? 12 : 4">
          <v-card flat>
            <v-card-title class="justify-center font-weight-bold"
              >第二場次</v-card-title
            >
            <v-card-text>
              <p class="lectureText">時間：<br />時間暫未公布敬啟期待</p>
              <p class="lectureText">地點：<br />地點暫未決定敬啟期待</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="isMobile ? 12 : 4">
          <v-card flat>
            <v-card-title class="justify-center font-weight-bold"
              >第三場次</v-card-title
            >
            <v-card-text>
              <p class="lectureText">時間：<br />時間暫未公布敬啟期待</p>
              <p class="lectureText">地點：<br />地點暫未決定敬啟期待</p>
            </v-card-text>
          </v-card>
        </v-col>

        <!--<div class="lectureBoxImg" id="lectureBoxImg02"></div>-->
      </v-row>
      <h1 class="lectureTitle">免費報名</h1>
      <div id="lectureForm">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="center">
            <v-col :cols="isMobile ? 12 : 6">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="姓名"
                required
              />
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="電話"
                required
              />
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="電子信箱"
                required
              />

              <v-select
                v-model="select"
                :items="items"
                :rules="[v => !!v || '場次必填']"
                label="場次"
                required
              />

              <v-btn
                :disabled="!valid"
                color="black"
                class="mr-4"
                outlined
                @click="validate"
              >
                確認送出
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Lecture",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      v => !!v || "姓名必填",
      v => (v && v.length <= 10) || "姓名超過字數，請重新輸入"
    ],
    phone: "",
    phoneRules: [
      v => !!v || "電話必填",
      v => (v && v.length <= 10) || "電話超過字數，請重新輸入"
    ],
    email: "",
    emailRules: [
      v => !!v || "電子郵件必填",
      v => /.+@.+\..+/.test(v) || "電子郵件為無效，請重新輸入"
    ],
    select: null,
    items: ["場次01", "場次02", "場次03"]
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss">
.lectureBoxImg {
  //background-color: rgb(224, 197, 140);
  flex: 1;
  min-height: 250px;
}
.lectureBoxContext {
  //border: 1px solid #000; min-height: 300px;
  flex: 1;
}

.lectureTitle {
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}
.lectureTitle::before,
.lectureTitle::after {
  background-color: #474747;
  display: inline-block;
  content: "";
  width: 40px;
  height: 2px;
  vertical-align: middle;
  margin: 0 15px;
}
.lectureWord01 {
  color: #002c74;
  font-size: 18px;
  margin: 20px 10px 10px 10px;
}
.lectureWord02 {
  color: #002c74;
  font-size: 18px;
  margin: 20px 0 20px 0;
}
.lectureText {
  color: #002c74;
  font-size: 16px;
  padding: 0 20px;
  line-height: 1.75;
}
</style>
