<template>
  <div id="main">
    <v-container>
      <v-row justify="center">
        <v-col :cols="isMobile?12:8">
          <h1 class="privacyPolicyTitle">chia隱私條款政策</h1>
            <h3 class="privacyPolicyWord01">簡介</h3>
            <br>
            <p class="pl-4">chia會保存所有使用者資訊，包含使用者到訪過的頁面、對話內容、問卷內容、以及註冊資訊。這些資訊包含您的個人資料，例如地址、電話等等。在沒有您的書面許可時，我們無法洩漏您的個人資料。只有在使用您所需要的服務時，chia會向您索取特定的資訊。</p>
            <p class="pl-4">我們保存的資訊不會用來分享、販售或出租。除了以下情形，在您使用chia同時，也同時提供您的資訊給我們：</p>
            <p class="pl-4">
              <ol>
                <li>當chia被收購或與其他公司合併時，我們將會轉移您的資訊。在此情況下，我們會以email，或在chia網站上通知您，您將配合新訂定的隱私條款。</li>
                <li>chia交由其他公司執行特定事項時，可能需要和其他公司分享您的資料，以便提供您所需要的產品或服務。在您的允許下，chia可以分享您的資訊，以提供您所需要的產品或服務。</li>
                <li>chia可能會提供您的個人資料以便協助調查、預防非法行動，或對非法活動採取行動。非法活動包含涉嫌詐欺、涉及潛在威脅他人人身安全、侵犯chia使用條款，或其他法律規定情形。</li>
              </ol>
            </p>
            <p class="pl-4">請仔細閱讀服務條款以保護您的權益。</p>
          <v-divider></v-divider>

          <div>
            <h3 class="privacyPolicyWord02">內容</h3>
            <br>
            <h3 class="privacyPolicyWord03">第一條：我們將您的資料做為何種用途</h3>
              <p class="pl-4 pt-3">當您註冊時，即代表您將個人資料提供給chia、並同意chia使用。結帳時，我們會取得並使用您的信用卡資料。除非顧客允許，否則您的顧客資料僅能用於chia相關的聯繫。</p>
            <br>
            <h3 class="privacyPolicyWord03">第二條：安全性</h3>
              <p class="pl-4 pt-3">我們會對您的信用卡資料和特殊訊息進行加密，但無法確保您個人資料100%的安全性，若有任何疑問，請email至<a href="mailto:service@chia.com" id="chiaEmail">service@chia.com</a></p>
            <br>
            <h3 class="privacyPolicyWord03">第三條：資料外流</h3>
              <p class="pl-4 pt-3">在特殊必要情況下，我們會將您的資料交由chia合作的公司。</p>
            <br>
            <h3 class="privacyPolicyWord03">第四條：客戶端客資料儲存</h3>
              <p class="pl-4 pt-3">chia擁有資料庫，然而我們沒有權利進入您的資料。確保您對資料的權利，我們不會直接與您的客戶聯絡，或將您的資料用商業用途。</p>
            <br>
            <h3 class="privacyPolicyWord03">第五條：Cookie</h3>
              <p class="pl-4 pt-3">為了自動辨識您的身分，我們會儲存一筆cookie在您的電腦。chia擁有再營銷工具可以記錄您的到訪記錄並提供您相關的廣告。</p>
            <br>
            <h3 class="privacyPolicyWord03">第六條：對隱私條款的更動</h3>
              <p class="pl-4 pt-3">chia保留在任何時間對隱私條款做更動的權利，請您經常閱讀最新的隱私條款。若有任何重大變更，我們會在此通知您。</p>
            <br>
            <h3 class="privacyPolicyWord03">第七條：免責聲明</h3>
              <p class="pl-4 pt-3">chia將盡最大努力維護資料之安全性，但不保證提供不間斷、即時、安全、無錯誤的儲存服務，chia不保證本服務之絕對安全性及無瑕疵無失誤，如因chia與相關網路系統及硬體設備之故障、失靈或人為操作上之疏失、駭客入侵等，而導致用戶無法傳輸、使用、或造成任何用戶資料內容（包括會員資料、文字、數字、圖片、聲音、影像）遺失、洩漏、缺漏、更新延誤或儲存上之錯誤，chia均不負任何責任，用戶不得向chia請求賠償。</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TermService",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  }
};
</script>

<style lang="scss">
.privacyPolicyTitle {
  text-align: center;
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}
.privacyPolicyWord01 {
  color: #000;
  font-size: 18px;
  border-left: 4px solid #474747;
  padding-left: 10px;
}
.privacyPolicyWord02 {
  color: #000;
  font-size: 18px;
  border-left: 4px solid #474747;
  padding-left: 10px;
  margin-top: 20px;
}
.privacyPolicyWord03 {
  color: #000;
  font-size: 18px;
  padding-left: 15px;
}
#chiaEmail:link, #chiaEmail:visited, #chiaEmail:link:active, #chiaEmail:visited:active {
  color: #000!important;
  text-decoration: none;
  font-size: 14px;
}

</style>