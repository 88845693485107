import { firestore, auth, Timestamp } from "@/plugins/firebase";
auth, Timestamp;

export const state = {
  isEMailVerified: false,
  isPhoneNumberVerified: false
};
export const getters = {
  firestoreColRef() {
    return firestore.collection("users");
  },
  firestoreDocRef(state, getters) {
    return state.firebaseUser ? getters.firestoreColRef.doc(getters.uid) : null;
  }
};
export const actions = {
  signUpWithEmailAndPassword({ getters }, { email, password }) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(userCredential => {
        const user = userCredential.user;
        return getters.firestoreColRef.doc(user.uid).set({
          email: email,
          join_time: Timestamp.now(),
          update_time: Timestamp.now()
        });
      });
  },
  updateUserData({ getters }, updateData) {
    return new Promise((resolve, reject) => {
      updateData.update_time = Timestamp.now();
      getters.firestoreDocRef
        ?.update(updateData)
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
  updateUserName({ dispatch }, { firstName, lastName }) {
    return new Promise((resolve, reject) => {
      dispatch("updateUserData", {
        first_name: firstName,
        last_name: lastName
      })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
  updatePhoneNumber({ dispatch }, phoneNumber) {
    return new Promise((resolve, reject) => {
      dispatch("updateUserData", {
        phone_number: phoneNumber
      })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
  updateJoinInvitationCode({ dispatch }, invitationCode) {
    return new Promise((resolve, reject) => {
      dispatch("updateUserData", {
        join_invitation_code: invitationCode
      })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  }
};
