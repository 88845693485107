<template>
  <v-app id="app">
    <Navbar v-if="$route.name !== 'StoreOnePageWeb'" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer />

    <loading-overlay :active.sync="isLoading" loader="dots" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading"
    })
  },
  watch: {
    "$vuetify.breakpoint.mobile": {
      immediate: true,
      handler(val) {
        this.$store.dispatch("setIsMobile", val);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002c74;
}

#main {
  //background-color: aquamarine;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002c74;
  margin: 50px 0 250px 0; //footer-bottom
}

header {
  width: 100%;
  // position: fixed;
  top: 0px;
  background-color: #fff;
  border-bottom: 1px solid #959398;
  text-align: center;
}
#headerNav {
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.headerNavUl {
  display: flex;
  list-style-type: none;
}
.headerNavLi {
  //border: 1px solid #000;
  flex: 1;
  font-size: 16px;
  padding: 10px 0;
}

footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  border-top: 1px solid #959398;
}

#footerNav {
  font-size: 20px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #c2beb5;
  @media (max-width: 376px) {
    width: 100%;
  }
}
.footerNavUl {
  //background-color: skyblue;
  list-style-type: none;
  flex: 1;
}
.appFooterTitle {
  color: #000;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
.footerNavLi {
  //background-color: rgb(226, 228, 141);
  font-size: 18px;
}

#footerDetail {
  //background-color: aquamarine;
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 10px 15px;
  font-size: 16px;
  color: #000;
  @media (max-width: 376px) {
    width: 100%;
  }
}
.footerDetailBox {
  flex: 1;
}
.footerSecUl {
  list-style-type: none;
}
.footerSecLi {
  float: right;
  padding-left: 15px;
}
.footerSecLi::after {
  clear: both;
}

.appAb {
  color: #002c74 !important;
  text-decoration: none;
  font-size: 20px;
}
.color {
  color: rgb(250, 78, 78) !important;
}
.appAs {
  color: #002c74 !important;
  text-decoration: none;
  font-size: 14px;
}

.service_title {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: #0071bc;
  color: #fff;
}
</style>
